.animation__loading{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.animation__loading img{
    width: 100px;
}
.animation__loading.active{
    display: flex;
}