.item__two{
    display: grid;
    grid-template-columns: 1fr 20%;
    gap: 25px;
}
.inner{
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
}
.results{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-top: 20px;
}
.res__color{
    width: 100%;
    height: 8px;
    border-radius: 10px;
    background-color: red;
}
.res__control{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.result{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}