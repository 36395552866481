.container{
    width: 100%;
    height: 100vh;
    background-color: #00000077;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inner{
    padding: 15px;
    background-color: #fff;
    border-radius: 15px;
}
.close{
    display: block;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 15px;
    top: 5px;
    cursor: pointer;
    transition: all .3s;
}
.close:hover{
    transform: scale(1.1);
}
.close span{
    width: 100%;
    display: block;
    position: absolute;
    top: calc(50% - 4px);
    height: 4px;
    background-color: #fff;
    border-radius: 5px;
    transform: rotate(45deg);
}
.close span+span{
    transform: rotate(-45deg);
}