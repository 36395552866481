.btn{
    width: 30px;
    height: 25px;
    position: relative;
    cursor: pointer;
    z-index: 99;
}
.btn__wrap{
    height: 60px;
    display: flex;
    align-items: center;
    width: max-content;
}
.btn span{
    display: block;
    position: absolute;
    height: 4px;
    background-color: #fff;
    border-radius: 5px;
    right: 0;
    transition: all .3s;
}
.f__l{
    width: 100%;
}
.s__l{
    width: 70%;
    top: calc(50% - 2px);
}
.t__l{
    width: 70%;
    top: calc(50% - 2px);
}
.fo__l{
    width: 40%;
    bottom: 0;
}
.btn:hover .t__l{
    width: 100%;
}
.btn:hover .s__l{
    width: 100%;
}
.btn:hover .fo__l{
    width: 100%;
}
.btn.active .f__l,.btn.active .fo__l{
    opacity: 0;
}
.btn.active .s__l{
    width: 90%;
    transform: rotate(45deg);
}
.btn.active .t__l{
    width: 90%;
    transform: rotate(-45deg);
}
.btn.active:hover span{
    width: 100%;
}
.container{
    display: flex;
    justify-content: right;
    align-items: center;
}
.control{
    display: flex;
    gap: 15px;
}
.control img{
    width: 30px;
}
.control a{
    display: block;
}