footer{
    margin-top: 50px;
    background-color: #323232;
}
.footer{
    padding: 30px 0;

    color: #fff;
    text-align: center;
    width: 90%;
    margin: auto;
}
.footer a{
    color: #fff;
}