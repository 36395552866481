.galery__inner{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-top: 50px;
}
.photo{
    max-width: 100%;
    display: block;
    margin: auto;
    border-radius: 15px;
}
.video{
    height: 100%;
    width: 100%;
    border-radius: 15px;
}
@media screen and (max-width:500px) {
    .galery__inner{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-top: 50px;
    }
}