.loading__wrap{
    width: 100%;
    height: 100vh;
    background-color: #00000077;
    position: fixed;
    display: none;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}
.loading__inner{
    box-shadow: 0 0 10px 3px #fff;
    padding: 100px;
    border-radius: 50%;
}
.loading__wrap.active{
    display: flex;
}
.loading__inner > .animation__loading img{
    width: 50vh;
}
.loading__text{
    position: relative;
    z-index: 5;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}
.loading__text p{
    text-shadow: 0 5px 10px #fff;

}