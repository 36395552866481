.case__wrap{
    width: 80%;
    margin: auto;
}
.case__contact__info__img__inner{
    position: relative;
}
.case__contact__info__img img{
    max-width: 100%;
    width: 100%;
}
.connections__form{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}
.connect__control{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}
.connect__for input{
    height: calc(100% - 16px);
}
.case__contact__info{
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 30px;
    margin-top: 50px;
}
.case__contact__info__img{
    position: relative;
}
.case__edit__img{
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 5px;
    cursor: pointer;
    transition: all .3s;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.case__edit__img:hover{
    transform: scale(1.1);
}
#uploadbtn{
    visibility: hidden;
}
.case__info{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    margin-top: 20px;
}
.case__info__card{
    display: grid;
    grid-template-columns: 30px 1fr;
    column-gap: 5px;
    align-items: center;
    width: 90%;
    margin: auto;
}
.case__info__card__img{
    display: flex;
    align-items: center;
}

.case__info__card__img img{
    width: 100%;
}
.case__info__text__center > p{
    text-align: center;
    margin: 0;
}
.case__info__text__center > div{
width: max-content;
margin-left: 10%;
}
.case__info__inner{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 10px;
    border: solid 3px #b399cb;
    border-radius: 10px;
}
.case__info__card__text{
    align-items: center;
}

.case__info__card__text > p{
    font-weight: 600;  
}
.case__info__card__text p{
    margin: auto;
}
.case__info__inner a{
    color: #000;
    text-decoration: none;
}
.activity{
    min-height: 50vh;
    background-color: #746d6d39;
    padding: 15px;
    max-height: 50vh;
    overflow-y: scroll;
}
.journal__line{
    display: flex;
    column-gap: 10px;
    align-items: center;
    width: 100%;
    position: relative;
}
.case__edit__img__ok{
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(43, 163, 184);
    padding: 2px 5px;
    cursor: pointer;
    transition: all .3s;
    color: #fff;
    font-weight: 900;
    font-size: 22px;
    border-radius: 5px;
    width: 30px;
    height: 30px;
}
.case__edit__img__ok:hover{
    transform: scale(1.1);
}
.er__con__wrap{
    border: solid 3px var(--main-color);
    margin-top: 15px;
    border-radius: 10px;
}
.er__con__inner{
    margin: auto;
}
.er__con__from{
    padding: 5px;
    background-color: #b399cb;
}
.er__con__inner p{
    margin: 3px;
}
.er__con__inner a{
    color: #000;
}
.plan__modal{
    width: 100%;
    height: 100vh;
    background-color: #00000077;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal__plan__inner{
    background-color: #fff;
    min-height: 90vh;
    max-height: 90vh;
    width: 50%;
    overflow-y: scroll;
    position: relative;
    padding: 0vh 10px;
}
.modal__plan__inner > h2{
    text-align: center;
}
.modal__plane__close{
    width: 20px;
    height: 30px;
    position: absolute;
    right: 10px;
    cursor: pointer;
    top: 10px;
    transition: all .3s;
    z-index: 99;
}
.modal__plane__close:hover{
    transform: rotate(90deg);
}
.modal__plane__close span{
    width: 100%;
    height: 4px;
    background-color: #000;
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    transform: rotate(45deg);
    border-radius: 5px;
}
.modal__plane__close span+span{
    transform: rotate(-45deg);
}
.modal__control__wrap{
    position: sticky;
    top: 0;
    background-color: #fff;
    padding-bottom: 10px;
}
.modal__plan__line{
    display: grid;
    grid-template-columns: 30px 1fr 1fr 2fr;
    column-gap: 10px;
}
.modal__plan__line textarea{
    width: 100%;
    resize: none;
}
.modal__plan__line{
    text-align: center;
}
.modal__plan__line{
    padding: 10px;
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.445);
    border-radius: 5px;
}
.modal__plan__number{
    display: flex;
    align-items: center;
    height: 100%;
}
.plan__icon{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgb(63, 63, 66, 0.8);
    position: relative;
    padding: 5px;
    margin-top: 20px;
    transition: all .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .8;
}
.plan__icon img{
    width: 25px;
}
.plan__icon:hover{
    cursor: pointer;
    opacity: 1;
}
.modal__control{
    width: 50%;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: #fff;
}
.green__back{
    background-color: rgb(196 255 217);
}
.red__back{
    background-color: rgb(255 196 196);
}
.blue__back{
    background-color: rgb(31, 181, 215);
}
.modal__plan__inner__lines{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;
    margin-top: 30px;
    padding-bottom: 30px;
}
.modal__plan__line.error{
    box-shadow: inset 0 0 5px 0 rgba(255, 0, 0, 0.9);
}
.modal__plan__line.good{
    box-shadow: inset 0 0 5px 0 rgba(0, 255, 0, 0.9);
}
.nameNewPlan{
    width: 90%;
    font-size: 20px;
    text-align: center;
    display: block;
    margin: auto;
    margin-top: 30px;
}
#modalSaveBtn{
    display: none;
}
#modalSaveBtn.active{
    display: block;
}
.wrap__plan{
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 30px;
    max-height: 500px;
}

.part__line__elem{
    width: 3px;
    height: 20px;
    background-color: #b399cb;
    position: relative;
    margin: auto;
}

.part__plan__date,.plan__created{
    width: max-content;
    margin: auto;
    padding: 10px;
    border: solid 3px #b399cb;
    border-radius: 10px;
}
.part__plan__description{
    padding: 10px;
    border: solid 3px #b399cb;
    border-radius: 10px;
}
.part__plan__btn{
    width: 70%;
    margin: auto;
}
.part__plan__control img{
    width: 20px;
}
.part__plan__control{
    display: flex;
    justify-content: space-between;
}
.case__edit__info__wrap,.mod__plan__wrap{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000bb;
    height: 100vh;
    overflow-y: scroll;
    color: #fff;
}
.add__case__btn{
    transition: all .3s;
}
.add__case__btn.good{
    background-color: rgb(21, 255, 0);
    color: #000;
    border: none;
    font-size: 20px;
}
.add__case__btn.close{
    background-color: rgb(255, 0, 0);
    color: #fff;
    border: none;
    font-size: 20px;
    margin-left: 10px;
}
.add__case__btn:hover{
    cursor: pointer;
    transform: scale(1.01);
}
.part__plan__control input[type="checkbox"]{
    width: 20px;
    height: 20px;
}
.savePlan{
    display: none;
}
.savePlan.active{
    display: block;
}
.editPlan{
    display: none;
}
.editPlan.active{
    display: block;
}
.part__plan__description textarea{
    width: 96%;
    margin-top: 5px;
}
.part__plan img{
    border-radius: 5px;
}
.deletePlan{
    width: 20px;
}
.deletePlan__wrap{
    display: flex;
    justify-content: right;
}
.part__plan__btn{
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mod__plan__inner{
    width: 100%;
    max-width: 500px;
    padding: 15px;
    background-color: #fff;
}
.mod__plan__wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
}
.mod__line{
    display: flex;
    justify-content: space-between;
}
.mod__line textarea{
    width: 100%;
    max-width: 500px;
}
.mod__lines{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
}
.plan__comment{
    width: 80%;
    margin: auto;
}
.__done__plan__good{
    background-color: rgba(134, 158, 141, 0.156);
}
.__done__plan__notgood{
    background-color: rgba(113, 94, 94, 0.192);
}
.connect__viewer__line{
    border: solid 3px #b399cb;
    padding: 10px;
    border-radius: 20px;
}
.connections__items{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 15px;
    column-gap: 15px;
    margin-top: 15px;
}
.connections__from .connect__viewer__line{
    background-color: #ded9e2;
}
.notes__field{
    position: relative;
    border: solid 3px #b399cb;
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    border-radius: 15px;
}
.notes__field img{
    cursor: pointer;
}

.notes__field textarea{
    outline: none;
    resize: none;
    border: none;
    margin: auto;
    display: block;
}
.notes__btn{
    margin: auto;
    margin-top: 10px;
    width: 90%;
}
.notes__btn button{
    padding: 5px 20px;
}
.select__plan{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.notes__viewer{
    background: #fff;
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: calc(100% - 16px);
    margin: auto;
    padding: 5px;
    display: grid;
    grid-auto-columns: 1fr;
    row-gap: 10px;
    border: solid 3px #b399cb;
    border-radius: 15px;
    
}
.helpes__viewer{
    background: #fff;
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: calc(100% - 16px);
    margin: auto;
    padding: 5px;
    display: grid;
    grid-auto-columns: 1fr;
    row-gap: 10px;
    border: solid 3px #b399cb;
    border-radius: 15px;   
}
.notes__mes__wrap{
    margin-top: 15px;
}
.notes__viewer__line > div{
    background: #ded9e2;
    padding: 5px;
    border-radius: 15px;
}
.notes__viewer__data{
    display: flex;
    align-items: center;
}
.notes__viewer__data > div{
    display: grid;
    row-gap: 5px;
}
.notes__viewer__line {
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 90%;
    margin: auto;
    padding: 5px;
    border-radius: 10px;
    column-gap: 15px;
}
.helpes__field{
    margin: auto;
    border: solid 3px #b399cb;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    margin-top: 10px;
}
.helpes__field img{
    cursor: pointer;
}
.helpes__field > textarea{
    resize: none;
    border: none;
    outline: none;
}
.helpes__btn{
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 20px;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
}
.helpes__viewer__line{
    width: 90%;
    margin: auto;
    background: #ded9e2;
    padding: 5px;
    border-radius: 10px;
}
.helpes__viewer__data a{
    color: #000;
    text-decoration: none;
}
.helpes__viewer__mess p{
    margin: .5em;
}
.helpes__mes__wrap{
    margin-top: 15px;
}

.helpes__viewer__data{
    display: flex;
    justify-content: space-between;
}
.connections__case{
    display: grid;
}
.export__pdf__inner{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
}
.item__pdf > label{
    margin-left: 15px;
}
.pdf__btn{
    width: 70%;
    margin: auto;
    margin-top: 30px;
}
.part__plan__date{
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.part__plan__date input{
    width: max-content;
}
/*_______________Copy text_______________*/
.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 70%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  .tooltip{
    cursor: pointer;
  }
  .modal__wrap__add{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 50px;
    padding-bottom: 100px;
    overflow-y: scroll;
    background-color: #fff;
  }
/*_______________END Copy text_______________*/

@media (max-width:600px){
    .connections__form {
        grid-template-columns: 1fr;
        row-gap: 10px;
    }
    .connections__items{
        grid-template-columns: 1fr;
    }
    .case__contact__info {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 30px;
        margin-top: 50px;
    }
    .case__info {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 20px;
    }
    .connections__case {
        display: grid;
        grid-template-columns: 1fr;
    }
    .Journal__and__plan {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 50px;
        margin-top: 50px;
    }
    .notes {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 50px;
        margin-top: 50px;
    }
    .media__content {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 30px;
        row-gap: 30px;
        margin-top: 50px;
    }
    img{
        width: auto;
        max-width: 100%;
    }
    .media__content img, .media__content video {
        width: auto;
        max-width: 100%;
        margin: auto;
    }
    .case__edit__info__wrap, .mod__plan__wrap {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000000bb;
        height: auto;
        overflow-y: scroll;
        color: #fff;
        padding-bottom: 200px;
    }
    .modal__plan__inner {
        background-color: #fff;
        min-height: 90vh;
        max-height: 90vh;
        width: 90%;
        overflow-y: scroll;
        position: relative;
        padding: 0vh 10px;
    }
    .modal__plan__line {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 10px;
        row-gap: 10px;
    }
    .plan__modal {
        width: 100%;
        height: 100vh;
        background-color: #00000077;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        align-items: center;
        justify-content: center;
    }
    .modal__plan__inner {
        background-color: #fff;
        min-height: auto;
        max-height: max-content;
        width: 90%;
        overflow-y: scroll;
        position: relative;
        padding: 0vh 10px;
        margin: auto;
        height: 50vh;
        padding-bottom: 100px;
    }
    .helpes__btn {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        align-items: center;
        justify-content: space-around;
    }
}