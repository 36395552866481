.modal__wrap{
    width: 100%;
    height: 100vh;
    background-color: #00000099;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal__inner{
    max-width: 80%;
    background-color: #fff;
    padding: 5%;
}
.info{
    width: 100%;
}
.modal__portal{
    width: 100%;
    height: 100vh;
    background-color: #00000099;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}