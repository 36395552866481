.iconButton {
  margin: 0 10px;
  padding: 5px;
  border: none;
  border-radius: 50%;
  color: white;
  font: inherit;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn_close {
  composes: iconButton;
  position: absolute;
  top: -15px;
  right: -40px;
  background-color: rgb(141 141 141 / 0%);
}

.btn_plus_contact {
  composes: iconButton;
  position: fixed;
  bottom: 15px;
  right: 80px;
}

button > span {
  width: 30px;
}

@media (max-width: 1490px) {
  .btn_plus_contact {
    position: absolute;
    top: 38px;
    bottom: auto;
    right: 10px;
  }
}

@media (max-width: 600px) {
  .btn_plus_contact {
    width: 40px;
    height: 40px;
  }
}
