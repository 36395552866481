.media__content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
    row-gap: 30px;
    margin-top: 50px;
}
.media__content img, .media__content video{
    width: 100%;
}
.case__contact__info__img__inner img{
    max-width: 100%;
}
.media__content>div{
    width: 100%;
    height: auto;
}

.form__add__media{
    width: 50%;
    margin: auto;
    margin-top: 30px;
    text-align: center;
    border: solid 3px #b399cb;
    padding: 15px;
    border-radius: 20px;
}
.form__inp__wr__grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}
.form__inp__wr{
    border: solid 3px #b399cb;
    border-radius: 20px;
    padding: 10px;
    display: grid;
    margin-top: 15px;
    row-gap: 15px;
}
.Journal__and__plan{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    margin-top: 50px;   
}
.grod{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
}
.grod input{
    margin-left: 10px;
}
.grod button{
    width: max-content;
    display: block;   
}
.notes{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    margin-top: 50px;   
}
.case__title span{
    color: #d2bee3;
}
.connections__wrap{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    margin-top: 50px;
}
.container__grid__two{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    margin-top: 50px;
}
.set__case__ico{
    width: 30px;
    position: absolute;
    right: 20px;
    top: 80px;
}
.set__case__ico .setImg{
    width: 100%;
    transition: all .7s;

}
.set__case__ico img{
    width: 100%;
    cursor: pointer;
}

.setImg:hover{
    transform: rotate(500deg);
    cursor: pointer;
}
@media (max-width:600px){
    .container__grid__two{
        grid-template-columns: 1fr;
    }
    .connections__wrap{
        grid-template-columns: 1fr;
        row-gap: 30px;
    }
    .case__contact__info > div > h1{
        text-align: center;
    }
    .form__inp__wr__grid {
        grid-template-columns: 1fr;
        row-gap: 10px;
        width: 87%;
    }
    .plan__active > h2{
        padding-bottom: 15px;
    }
    .case__contact__info {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 30px;
        margin-top: 50px;
        row-gap: 10px;
    }
    .case__info {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
    .connections__case {
        display: grid;
        grid-template-columns: 1fr;
    }
    .Journal__and__plan {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 50px;
        margin-top: 50px;
        row-gap: 50px;
    }
    .notes {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 50px;
        margin-top: 50px;
        row-gap: 50px;
    }
    .media__content {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 30px;
        row-gap: 30px;
        margin-top: 50px;
    }
    .form__add__media {
        width: 90%;
        margin: auto;
        margin-top: 30px;
        text-align: center;
        
        padding: 15px;
    }
    img{
        width: auto;
        max-width: 100%;
    }
    .media__content img, .media__content video {
        width: auto;
        max-width: 100%;
        margin: auto;
    }
    .case__edit__info__wrap, .mod__plan__wrap {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000000bb;
        height: auto;
        overflow-y: scroll;
        color: #fff;
        padding-bottom: 200px;
    }
}