.wrap__rec__form{
    width: 300px;
    display: grid;
    margin: auto;
    margin-top: 150px;
}
.recovery__wrap{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}