.add__case{
    width: 80%;
    margin: auto;
    padding-top: 50px;
}
.form__add__case{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;
}
.section__form{
    display: grid;
    grid-template-columns: 1fr 5fr;
    padding: 5px;
    grid-template-areas: 
    "b c c"
    ". a a";
}
.label{
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    height: 100%;
    grid-area: b;
}
.input__text{
    padding-left: 10px;
    height: 30px;
    font-size: 18px;
    grid-area: c;
}
.select{
    padding-left: 10px;
    height: 30px;
    font-size: 18px;
    grid-area: c;
}
.error__message{
    grid-area: a;
    position: relative;
}
.error__message > p{
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    color: red;
}
.form__category{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.form__category__block{
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    padding: 5px;
}
.form__category__block input[type=checkbox]{
    width: 30px;
    height: 30px;
    margin: auto;
    border-radius: 10px;
}
.modal__errors{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.modal__errors__black{
    background-color: #00000077;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal__errors__inner{
    padding: 30px;
    border-radius: 20px;
    background-color: #fff;
    color: red;
}